import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { getLoggedInUser } from '../services/ssoUtils'

const defaultState = { user: {} }

const UserContext = React.createContext(defaultState)

export function UserCookieProvider({ children }) {
  const [user, setUser] = useState(getLoggedInUser())

  const logInUser = (userDetails) => {
    setUser(userDetails)
  }

  const removeUser = () => {
    setUser(defaultState)
  }

  return (
    <UserContext.Provider
      value={{
        user,
        logInUser,
        removeUser,
      }}>
      {children}
    </UserContext.Provider>
  )
}

UserCookieProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UserContext
