import { stringifyUrl, parseUrl } from 'query-string'

export const getObjectProperty = (cases, key, defaultCase) => {
  if (Object.prototype.hasOwnProperty.call(cases, key)) {
    return cases[key]
  }
  return defaultCase
}

export const getEnvironmentOption = (options, environment = 'staging') => {
  if (!options) {
    return false
  }
  return options[environment]
}

export const stripHtml = (html) => {
  return html ? html.replace(/(<([^>]+)>)/gi, '') : ''
}

export const appendQueryParams = (inputUrl = '', params = {}) => {
  if (!inputUrl) {
    return inputUrl
  }
  const { url, query } = parseUrl(inputUrl, { arrayFormat: 'comma' })
  const resultObj = {
    url,
    query: {
      ...query,
      ...params,
    },
  }
  return stringifyUrl(resultObj, { arrayFormat: 'comma' })
}

export const windowExists = typeof window !== 'undefined' && window

export const randomId = () =>
  Math.random()
    .toString(36)
    .slice(2)

export const sortByObjectProperty = (arr, property, order = 'ASC') => {
  if (!arr || arr.length === 0 || !arr.length) {
    return []
  }

  if (!property) {
    return arr
  }

  if (order === 'DESC') {
    return arr.sort((a, b) => b[property] - a[property])
  }

  return arr.sort((a, b) => a[property] - b[property])
}

// Scroll to an element function
export const scrollToElement = (el, offsetY = 10) => {
  if (!el) {
    return
  }

  const y = el.getBoundingClientRect().top + window.scrollY - offsetY

  window.scrollTo({
    top: y,
    behavior: 'smooth',
  })
}

export const ellipsify = (str, max) => {
  if (str.length > max) {
    return `${str.substring(0, max)}...`
  }
  return str
}
