import { getEnvironmentOption } from '../../utils/index'

export const getIdentityConfig = (
  theme = process.env.GATSBY_CHILD_CONFIG || process.env.GATSBY_BUILD_THEME
) => {
  const credentials = {
    lionsCreativity: {
      integration: {
        domain: 'auth-integration.lionscreativity.com',
        clientId: '8v4gt68AToJqJerojPNXT8UrKHmh2x81',
      },
      test: {
        domain: 'auth-test.canneslions.com',
        clientId: '4JwrqemNjpHMOxaXeEYC4uQZp8B3AlW4',
      },
      staging: {
        domain: 'auth-staging.lionscreativity.com',
        clientId: 'o9mmSbBaMko0eR8xP73VZ0DUTfCcJ2BU',
      },
      production: {
        domain: 'auth.lionscreativity.com',
        clientId: 'kk50OSOPsCc5qJBYypGfXNGJCvgQgbEL',
      },
    },
    lionsLive: {
      integration: {
        domain: 'auth-integration.lionscreativity.com',
        clientId: '8v4gt68AToJqJerojPNXT8UrKHmh2x81',
      },
      test: {
        domain: 'auth-test.canneslions.com',
        clientId: 'tPtACW0WwqOXYhlkTlPurwANOXOwgEcH',
      },
      staging: {
        domain: 'auth-staging.lionscreativity.com',
        clientId: 'tOKsPNEU08ButX7sKwb00G4MDZRlHWvw',
      },
      production: {
        domain: 'auth.lionscreativity.com',
        clientId: 'YxZ2g2scbqQUR8S7U2Selrcs2YLLnbcU',
      },
    },
  }

  const defaultAuthCondition = (user) => user && user.loggedIn

  const defaultSetup = {
    identity: 'sitecore', // one of 2 options 'auth0' , 'sitecore', if auth0 selected, you need to add auth0 property, containing an object with domain and clientId
    authCondition: defaultAuthCondition, // Authorization condition, defaults to check whether user is loggedIn but can include logic involving plan types, etc.
    redirectCallbackPages: null, // Array of page slugs that should go to /callback page after log in (that page currently handles redirect to Dashboard)
  }

  const setup = {
    horizon: defaultSetup,
    lionsCreativity: {
      identity: 'auth0',
      auth0: getEnvironmentOption(
        credentials.lionsCreativity,
        process.env.GATSBY_ENV
      ),
      authCondition: defaultAuthCondition,
      redirectCallbackPages: ['/', '/membership'],
    },
    lionsLive: {
      identity: 'auth0',
      auth0: getEnvironmentOption(
        credentials.lionsLive,
        process.env.GATSBY_ENV
      ),
      authCondition: (user) => user && user.loggedIn && !!user.planName,
      redirectCallbackPages: null,
    },
  }

  const brand = theme || 'horizon'
  return setup[brand] || defaultSetup
}

export default getIdentityConfig
