import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Auth0Provider } from '@auth0/auth0-react'
import { UserCookieProvider } from './userContext'
import { getIdentityConfig } from './config/identityConfig'

function IdentityProvider({ children, identity }) {
  const { auth0, redirectCallbackPages } = getIdentityConfig()

  const onRedirectCallback = ({ returnTo }) => {
    if (redirectCallbackPages && redirectCallbackPages.includes(returnTo)) {
      // Redirect to Callback page to apply additional logic
      // state is being passed down to the Callback page for any further redirects
      navigate('/callback', {
        replace: true,
        state: {
          returnTo,
        },
      })
      return
    }
    navigate(returnTo || '/', { replace: true })
  }

  if (identity === 'sitecore') {
    return <UserCookieProvider>{children}</UserCookieProvider>
  }

  return (
    <Auth0Provider
      domain={auth0.domain}
      clientId={auth0.clientId}
      onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  )
}

IdentityProvider.propTypes = {
  children: PropTypes.node.isRequired,
  identity: PropTypes.string.isRequired,
}

export default IdentityProvider
