const theme = {
  fullWidthIframe: true,
  scrollOffset: 48,
  videoPlatform: {
    carouselsUrl: null,
    eventsUrl: null,
    speakersUrl: {
      production:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/me2022',
      staging:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/me2022',
      test:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/me2022',
      integration:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/me2021',
    },
    pagePrefix: { slug: 'whatshappening' },
  },
  speakers: {
    pageTitle: 'Speakers',
    pageDescription: `See the line-up for Money20/20 Europe and learn more about each speaker and the sessions they'll lead.`,
    shareButtons: true,
  },
  notFound: {
    cta: {
      label: 'Back Home',
      url: '/',
    },
    message: `But back at Money20/20, you'll find heaps of original thinking, provocative views and critical insights on what's next for the future of money`,
    title: 'Nothing to see here.',
  },
}

export default theme
