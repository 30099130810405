module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lions Creativity","short_name":"Lions Creativity","background_color":"#F4F4F4","theme_color":"#212121","icon":"src/assets/images/Cannes.png","start_url":"/","display":"minimal-ui","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f364a7705c06cf2849591f5b59cae6a0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WT7QSVS","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/404/","/404.html"],"crumbLabelUpdates":[{"pathname":"/what-ive-learnt","crumbLabel":"What I've Learnt..."},{"pathname":"/moneyfest","crumbLabel":"MoneyFest"},{"pathname":"/moneypot","crumbLabel":"MoneyPot"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
