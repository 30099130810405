import lionsLive from './lionsLive'

const theme = {
  children: {
    lionsLive,
  },
  meta: {
    fbDomainVerification: 'x43esa0ldi9erc8ii49u8alo3w47ki',
  },
  header: {
    position: 'sticky',
    place: 'header',
    // variation: 'mega',
    variation: 'nav',
    loginRequired: true,
  },
  annoucementVariation: {
    type: 'strip',
  },
  footer: {
    type: 'primary',
    // type: 'menu',
  },
  scrollOffset: 128,
  showBreadcrumbs: false,
  name: 'Lions Creativity',
  digest: {
    isEnabled: true,
    pageSize: 12,
  },
  navigationHomeLink: '/',
  notFound: {
    cta: {
      label: 'Back to home page',
      url: '/',
    },
    message: `But back at Lions Creativity, you’ll find original thinking and provocative views on what’s next from the most creative companies in the world.`,
  },
}

export default theme
