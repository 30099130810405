const theme = {
  header: {
    position: 'static',
    place: 'header',
    loginRequired: true,
  },
  scrollOffset: 10,
  featuredVideo: {
    overlay: {
      title: 'Create a free account',
      subtitle: `or log in with your existing account details`,
      buttonVariant: 'primary',
    },
  },
  sitecore: {
    SSO: {
      prod: 'live-auth.ascential.com',
      staging: 'staging-live-auth.ascential.com',
      test: 'uat-live-auth.ae-sandbox.net',
      integration: 'live-auth.ascential.com',
    },
    urlPath: 'dclive',
    cookieLifetime: 28,
    logoutPath: 'dclive/',
  },
  videoPlatform: {
    eventsUrl: {
      production:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/events/dc2021',
      staging:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/events/dc2021',
      test:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/events/dc2021/uat',
      integration:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/events/dc2021/uat',
    },
    speakersUrl: {
      production:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/dc2021',
      staging:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/dc2021',
      test:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/dc2021/uat',
      integration:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/dc2021/uat',
    },
    featuredVideoOverlay: {
      title: `<h1>BROADCASTING<br />
            LIVE<br />
            NOW</h1>`,
      button: {
        text: 'Watch Now',
      },
    },
    feedbackForm: {
      showForm: true,
      prod:
        'https://portal-api.ascentialevents.com/api/ContentUserRating/rating',
      staging:
        'https://dev-rating-cl-content.ae-sandbox.net/api/ContentUserRating/rating',
      integration:
        'https://dev-rating-cl-content.ae-sandbox.net/api/ContentUserRating/rating',
    },
    liveTitleUrl: 'https://portal-api.ascentialevents.com/NowShowing.html',
    timezoneIndicator: 'EDT',
    videoOverlay: {
      title: 'Log in with your account',
      subtitle: 'Strictly invitation only',
      buttonText: 'Log in',
      buttonVariant: 'primary',
    },
  },
  name: 'Digital Commerce Live',
  notFound: {
    message: `But back at Digital Commerce Live, you’ll find hours of original thinking and provocative views on what’s next from the most creative companies in the world.`,
  },
  copyrightText: 'Ascential plc',
  carouselTextLimit: 190,
}

export default theme
