export const getClientId = () => {
  try {
    // eslint-disable-next-line no-undef
    const tracker = ga.getAll()[0]
    return tracker.get('clientId')
  } catch (e) {
    return 'n/a'
  }
}

export const trackAddToCalendarClick = (
  label,
  title,
  eventName = 'button.click'
) => {
  if (typeof dataLayer !== 'undefined') {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: eventName,
      buttonName: label,
      buttonCalendarName: title,
      gacid: getClientId(),
    })
  }
}

export const trackBtnClick = (
  label,
  url,
  eventName = 'button.click',
  value = ''
) => {
  if (typeof dataLayer !== 'undefined') {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: eventName,
      buttonName: label,
      buttonCalendarName: url,
      buttonValue: value,
      gacid: getClientId(),
    })
  }
}

export const trackLinkClick = (
  label,
  url,
  type = 'link',
  eventName = 'link.click'
) => {
  if (typeof dataLayer !== 'undefined') {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: eventName,
      linkName: label,
      linkUrl: url,
      linkType: type,
      gacid: getClientId(),
    })
  }
}

let originalLocation = null

export const trackPageView = (pageUrl, pageTitle, authId = '') => {
  if (!originalLocation && typeof document !== 'undefined') {
    originalLocation = document.location.href
  }
  if (typeof dataLayer !== 'undefined') {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: 'VirtualPageview',
      externalAuthId: authId,
      virtualPageURL: pageUrl,
      virtualPageTitle: pageTitle,
      originalLocaion: originalLocation,
    })
  }
}

export const trackSearch = (
  searchTerm,
  searchContext = 'events',
  eventName = 'search'
) => {
  if (typeof dataLayer !== 'undefined') {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: eventName,
      searchContext,
      searchTerm,
    })
  }
}

export const trackSocialLinkClick = (
  label,
  url,
  type = 'link',
  eventName = 'link.click'
) => {
  if (typeof dataLayer !== 'undefined') {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: eventName,
      socialChannel: label,
      linkUrl: url,
      linkType: type,
      gacid: getClientId(),
    })
  }
}

export const trackTabClick = (tabName, eventName = 'tab.click') => {
  if (typeof dataLayer !== 'undefined') {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: eventName,
      tabName,
    })
  }
}

export const trackVideo = (
  name,
  progress,
  category,
  talents,
  videoLength,
  eventName = 'video.click'
) => {
  if (typeof dataLayer !== 'undefined') {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: eventName,
      videoName: name,
      videoCategory: category,
      videoLength,
      videoProgress: progress,
      videoTalent: talents,
    })
  }
}

export default trackBtnClick
