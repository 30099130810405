import { parse, serialize } from 'cookie'
import hmacSHA256 from 'crypto-js/hmac-sha256'
import axios from 'axios'
import getThemeConfig from '../providers/config/themeConfig'

export const config = getThemeConfig()
const isProd = process.env.GATSBY_ENV === 'production'

// COOKIE SECTION ----
export const getCookie = () => {
  if (typeof document === 'undefined') return null
  try {
    const cookie = window.atob(parse(document.cookie).ssoAccount)
    // decoding because JavaScript built-in functions btoa and atob do not support Unicode strings
    return decodeURIComponent(cookie)
  } catch (e) {
    return null
  }
}

export const setCookie = (value = {}, location = {}) => {
  if (typeof document === 'undefined') return
  const cookieVal = {
    email: value.Email,
    firstName: value.FirstName,
    lastName: value.LastName,
    company: value.CompanyName,
    loggedIn: !!value.Email,
    userId: value.userId,
  }
  // encoding because JavaScript built-in functions btoa and atob do not support Unicode strings
  const encodedCookie = encodeURIComponent(JSON.stringify(cookieVal))
  const base64Val = window.btoa(encodedCookie)
  const { cookieLifetime } = config.sitecore
  document.cookie = serialize('ssoAccount', base64Val, {
    domain: location.hostname,
    path: '/',
    secure: false,
    maxAge: (isProd ? cookieLifetime : 1) * 24 * 60 * 60, // 1 day for non prod, 14 days for prod,
  })
}

export const parseCookie = (val = '{}') => JSON.parse(val)

export const getLoggedInUser = () => {
  return parseCookie(getCookie()) || {}
}

export const deleteCookie = (location = {}) => {
  if (typeof document === 'undefined') return
  document.cookie = serialize('ssoAccount', '', {
    domain: location.hostname,
    path: '/',
    secure: false,
    expires: new Date('01 Jan 2000 00:00:01 GMT'),
  })
}

// ----COOKIE SECTION END----

const encodeUser = (email, ssotoken) =>
  hmacSHA256(
    `${email}|${ssotoken}|true`.toLowerCase(),
    'ASCENTIALEVENTS3b021c70e1fa47fe92bcc8c07d8ea525'
  ).toString()

let ssoDomain
switch (process.env.GATSBY_ENV) {
  case 'production':
    ssoDomain = config.sitecore.SSO.prod
    break
  case 'staging':
    ssoDomain = config.sitecore.SSO.staging
    break
  case 'test':
    ssoDomain = config.sitecore.SSO.test
    break
  default:
    ssoDomain = config.sitecore.SSO.staging
}

export const redirectURL = (location) =>
  `http://${ssoDomain}/account/ssoredirect?urlLoginPath=${config.sitecore.urlPath}&redirect=${location.origin}${location.pathname}${location.search}`

export const getUser = (email = '', ssotoken = '') => {
  const encodedEmail = encodeURIComponent(email.trim())
  const encodedSSOtoken = ssotoken.trim()
  const encodedKey = encodeUser(email.trim(), encodedSSOtoken)
  const path = `https://${ssoDomain}/account/ssocheck?ssotoken=${encodedSSOtoken}&email=${encodedEmail}&detailed=true&key=${encodedKey}`
  const opts = {
    method: 'GET',
    data: new FormData(),
    url: path,
  }
  const errMessage = { error: true, reason: 'Something went wrong' }
  return axios
    .request(opts)
    .then((resp) => {
      if (resp.data) {
        const { statuscode, message } = resp.data
        switch (statuscode) {
          case 200:
            return { ...resp.data.detailed, userId: resp.data.user_id || null }
          case 403:
            return { error: true, status: 403, reason: message }
          default:
            return errMessage
        }
      }
      return errMessage
    })
    .catch(() => {
      return errMessage
    })
}

export const getLogoutUrl = (location) => {
  return `https://${ssoDomain}/${config.sitecore.logoutPath}logout?redirect=${location.origin}`
}
