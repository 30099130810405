import defaultsDeep from 'lodash.defaultsdeep'
import { ascential, dcl, horizon, lionsCreativity, money2020 } from './themes'

const themes = {
  ascential,
  dcl,
  horizon,
  lionsCreativity,
  money2020,
}

const getThemeConfig = () => {
  const brand = process.env.GATSBY_BUILD_THEME || 'horizon'
  const childTheme = process.env.GATSBY_CHILD_CONFIG
  const theme = themes[brand] || horizon
  const themeConfig = defaultsDeep(theme, horizon)

  if (!childTheme) {
    return themeConfig
  }

  const childThemeConfig = defaultsDeep(
    themeConfig.children[childTheme],
    themeConfig
  )
  delete childThemeConfig.children

  return childThemeConfig
}

export default getThemeConfig
