const theme = {
  eventDate: '21st - 25th June',
  header: {
    position: 'sticky',
    variation: null,
    loginRequired: true,
  },
  scrollOffset: 200,
  videoPlatform: {
    livePlayer: 'brightcove',
    hasPayWall: true,
    carouselsUrl: {
      production:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/carousels/cll2021',
      staging:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/carousels/cll2021',
      test:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/carousels/cll2021/uat',
      integration:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/carousels/cll2021/uat',
    },
    eventsUrl: {
      production:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/events/cll2021',
      staging:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/events/cll2021',
      test:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/events/cll2021/uat',
      integration:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/events/cll2021/uat',
    },
    speakersUrl: {
      production:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/cll2021',
      staging:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/cll2021',
      test:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/cll2021/uat',
      integration:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/cll2021/uat',
    },
    feedbackForm: {
      showForm: true,
      prod:
        'https://portal-api.ascentialevents.com/api/ContentUserRating/rating',
      staging:
        'https://dev-rating-cl-content.ae-sandbox.net/api/ContentUserRating/rating',
      integration:
        'https://dev-rating-cl-content.ae-sandbox.net/api/ContentUserRating/rating',
    },
    liveTitleUrl: 'https://portal-api.ascentialevents.com/NowShowing.html',
    multiBrand: false,
    timezoneIndicator: 'BST',
    featuredVideoOverlay: {
      title: `<h1>CANNES LIONS LIVE<br />STREAMING <br />NOW</h1>`,
      titleAuth: `<h1>ACCESS <br /> CANNES LIONS LIVE</h1>`,
      button: {
        link: '/buy-now',
        text: 'Log in',
        textAuth: 'Buy now',
        variant: 'secondary',
      },
    },
  },
  speakers: {
    labelSingular: 'Talent',
    labelPlural: 'Talent',
    pageDescription: `See the line-up for Cannes Lions Live 2021 and learn more about each speaker and the sessions they'll lead.`,
    pageTitle: 'Talent | Speakers at Cannes Lions Live 2021',
    pathSingular: 'talent',
    pathPlural: 'talent',
    queryParams: {},
    shareButtons: true,
  },
  name: 'Cannes Lions Live',
  showInPartnershipText: false,
  notFound: {
    message: `But back at Cannes Lions Live, you’ll find hours of original thinking and provocative views on what’s next from the most creative companies in the world.`,
    cta: {
      label: 'Home',
      url: '/',
    },
  },
  carouselTextLimit: 240,
}

export default theme
