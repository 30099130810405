import { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import { getCookie, setCookie, getUser, getLoggedInUser } from './ssoUtils'
import { trackPageView } from './tracking'
import UserContext from '../providers/userContext'

function SitecoreSSO({ children, props: { location } }) {
  // this is specific to sitecore and needs to consume directly the context
  const { user, logInUser } = useContext(UserContext)
  const cookie = getCookie()
  const { email, ssotoken } =
    location && location.search ? queryString.parse(location.search) : {}
  const blockTracking = location?.state?.blockTracking

  if (!cookie) {
    useEffect(() => {
      const getRemoteUser = async () => {
        const result = await getUser(email, ssotoken)
        if (!result.error) {
          setCookie(result, location)
          logInUser(getLoggedInUser())
          navigate(location.pathname)
        }
        if (result.error && result.status === 403) {
          navigate('/restricted')
        }
      }
      if (email && ssotoken) {
        getRemoteUser()
      }
    }, [email, ssotoken])
  } else {
    useEffect(() => {
      if (email && ssotoken) {
        navigate(location.pathname)
      }
    }, [email, ssotoken])
  }

  if (!blockTracking) {
    const userIdExists = user && user.userId
    const pageTitle = location.pathname.replace('/', '') || 'home-page'
    const pageUrl = location.pathname + location.search + location.hash
    const authId = userIdExists ? `sitecore${user.userId}` : ''
    trackPageView(pageUrl, pageTitle, authId)
  }

  return children
}

SitecoreSSO.propTypes = {
  children: PropTypes.node,
  props: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object,
  }),
}

SitecoreSSO.defaultProps = {
  children: null,
  props: {},
}
export default SitecoreSSO
