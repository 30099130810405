import { useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import UserContext from './userContext'
import { getIdentityConfig } from './config/identityConfig'
import { redirectURL, deleteCookie, getLogoutUrl } from '../services/ssoUtils'

export const useIdentity = () => {
  const { authCondition, identity } = getIdentityConfig()

  if (identity === 'sitecore') {
    const { user, logInUser, removeUser } = useContext(UserContext)
    return {
      user: {
        ...user,
        planName: null,
      },
      setUser: logInUser,
      login: () => {
        window.location.href = redirectURL(window.location)
      },
      logout: () => {
        const cleanUpUser = async () => {
          deleteCookie(window.location)
          return removeUser()
        }
        cleanUpUser()
        window.location.href = getLogoutUrl(window.location)
      },
      loggedIn: user.loggedIn,
      isAuthorized: user.loggedIn,
    }
  }
  const {
    user,
    isAuthenticated,
    logout,
    loginWithRedirect,
    getAccessTokenSilently,
    ...rest
  } = useAuth0()

  const plan = user && user['http://lions.membership/user/plan']
  const userProps = {
    ...user,
    getToken: (options = {}) => getAccessTokenSilently(options),
    loggedIn: isAuthenticated,
    loading: rest.isLoading,
    planName: plan && plan.plan_name,
  }

  const isAuthorized = authCondition(userProps)

  return {
    user: userProps,
    login: () => {
      const redirectUrl = window.location.origin
      const loginOpts = {
        redirectUri: redirectUrl,
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}${window.location.hash}`,
        },
      }
      loginWithRedirect(loginOpts)
    },
    logout: () => {
      return logout({ returnTo: window.location.origin })
    },
    loggedIn: isAuthenticated,
    isAuthorized,
    ...rest,
  }
}

export default useIdentity
