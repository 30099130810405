import { trackPageView } from '../services/tracking'

const blocklistedPaths = []

// eslint-disable-next-line import/prefer-default-export
export const handlePageView = (user, location) => {
  const userIdExists = user && user.sub
  const pageTitle = location.pathname.replace('/', '') || 'home-page'
  const pageUrl = location.pathname + location.search + location.hash
  // Remove the pipe character for tracking purposes
  const authId = userIdExists ? user.sub.split('|').join('') : ''
  const blockTracking = location?.state?.blockTracking

  if (!blocklistedPaths.includes(location.pathname) && !blockTracking) {
    trackPageView(pageUrl, pageTitle, authId)
  }
}
