/* eslint-disable camelcase */
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { navigate } from 'gatsby'
import { handlePageView } from './pageViews'
import { useIdentity } from './identityContext'

function Auth0Errors({ children, props: { location } }) {
  const { user } = useIdentity()
  useEffect(() => {
    if (!user.loading) handlePageView(user, location)

    const { error, error_description, state } =
      location && location.search ? queryString.parse(location.search) : {}

    if (error && error_description && state) navigate('/restricted')
  }, [location, user.loading])
  return children
}

Auth0Errors.propTypes = {
  children: PropTypes.node,
  props: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object,
  }),
}

Auth0Errors.defaultProps = {
  children: null,
  props: {},
}
export default Auth0Errors
