/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// eslint-disable-next-line import/prefer-default-export

import React from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import Auth from './src/services/sitecoreSSO'
import Auth0Errors from './src/providers/Auth0Errors'
import IdentityProvider from './src/providers/IdentityProvider'
import { getIdentityConfig } from './src/providers/config/identityConfig'
import getThemeConfig from './src/providers/config/themeConfig'
import { scrollToElement } from './src/utils/index'

// Polyfill for smoothscroll
// (some browsers like Safari don't support the smooth behaviour of window.scrollTo yet)
smoothscroll.polyfill()

const { identity } = getIdentityConfig()
const { scrollOffset } = getThemeConfig()

// eslint-disable-next-line react/prop-types
export const wrapPageElement = ({ element, ...rest }) => {
  if (identity === 'sitecore') {
    return <Auth {...rest}>{element}</Auth>
  }
  return <Auth0Errors {...rest}>{element}</Auth0Errors>
}

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <IdentityProvider identity={identity}>{element}</IdentityProvider>
)

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import('intersection-observer')
    // eslint-disable-next-line no-console
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

export const onRouteUpdate = ({ location }) => {
  const { hash } = location

  if (!hash) {
    return
  }

  // Get the anchor path cleaned of special chars
  const anchorString = hash && hash.slice(1).replace(/%20/g, ' ')
  // Find the element with ID matching the anchorString
  const element = anchorString && document.getElementById(anchorString)

  // In case of anchor links
  if (element) {
    scrollToElement(element, scrollOffset)
  }
}
