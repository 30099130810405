const theme = {
  sitecore: {
    SSO: {
      prod: 'eurobest-uat.lionsfestivals.com', // prod env
      staging: 'eurobest-uat.lionsfestivals.com', // uses YAP, it is the pre-prod env
      test: 'eurobest-uat.lionsfestivals.com', //
      integration: 'eurobest-uat.lionsfestivals.com', //
    },
    urlPath: 'ascential-live',
  },
  header: {
    position: 'static',
  },
  name: 'Ascential',
  videoPlatform: {
    eventsUrl: {
      production:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/events/cl2020',
      staging:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/events/cl2020',
      test:
        'https://dev-cl-content.ae-sandbox.net/api/Event/getEvents?key=cl-QethAcU5Pa2RE&festival_year=2020',
      integration:
        'https://dev-cl-content.ae-sandbox.net/api/Event/getEvents?key=cl-QethAcU5Pa2RE&festival_year=2020',
    },
    speakersUrl: {
      production:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/cl2020',
      staging:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/cl2020',
      test:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/cl2020',
      integration:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/cl2020',
    },
    feedbackForm: {
      showForm: true,
      prod:
        'https://portal-api.ascentialevents.com/api/ContentUserRating/rating',
      staging:
        'https://dev-rating-cl-content.ae-sandbox.net/api/ContentUserRating/rating',
      integration:
        'https://dev-rating-cl-content.ae-sandbox.net/api/ContentUserRating/rating',
    },
  },
}

export default theme
